/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";

export function SidebarLinks(props) {
    let location = useLocation();
    const { routes, onLinkClick, openedByUser } = props;

    const activeRoute = (routeName) => {
        return location.pathname.includes(routeName);
    };

    const createLinks = (routes) => {
        return routes
            .filter(route => route.sidebar !== false)
            .map((route, index) => {
                if (route.layout === "/admin" || route.layout === "/auth") {
                    const isActive = activeRoute(route.path);
                    const textClass = isActive ? "font-bold text-brand-500 dark:text-white" : "font-medium text-gray-600";
                    const linkClass = isActive ? "font-bold text-navy-700 dark:text-white" : "font-medium text-gray-600";

                    return (
                        <Link
                            key={index}
                            to={`${route.layout}/${route.path}`}
                            onClick={() => openedByUser && onLinkClick && onLinkClick()}
                        >
                            <div className="relative mb-3 flex hover:cursor-pointer">
                                <li className="my-[3px] flex cursor-pointer items-center px-8">
                                    <span className={textClass}>
                                        {route.icon || <DashIcon />}
                                    </span>
                                    <p className={`leading-1 ml-4 flex ${linkClass}`}>
                                        {route.name}
                                    </p>
                                </li>
                                {isActive && <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />}
                            </div>
                        </Link>
                    );
                }
                return null;
            });
    };


    return createLinks(routes);
}

export default SidebarLinks;
