import React, { useState } from 'react';
import Button from "components/button/Button";
import { MdKeyboardArrowRight } from "react-icons/md";
import DateInputWithCalendar from 'components/calendar/DateInputWithCalendar';
import UnifiedDropdown from 'components/dropdown/UnifiedDropdown';
import { parseISO, format } from 'date-fns';

const StepOne = ({
    reservationDetails,
    setReservationDetails,
    cars,
    locations,
    error,
    setError,
    handleNextStep,
    isEditing = false,
}) => {
    const times = generateTimes();

    const [isPickupTimeOpen, setIsPickupTimeOpen] = useState(false);

    const handleChange = (e) => {
        let name, value;

        if (e.target && e.target.name) {
            name = e.target.name;
            value = e.target.value;
        } else {
            console.warn('handleChange received unexpected input', e);
            return;
        }

        setReservationDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));

        if (error[name]) {
            setError((prevError) => ({
                ...prevError,
                [name]: false,
            }));
        }

        if (name === 'pickupDate') {
            setIsPickupTimeOpen(true);
            setReservationDetails((prevDetails) => ({
                ...prevDetails,
                pickupTime: '11:00',
            }));
        }

        if (name === 'pickupTime') {
            if (reservationDetails.pickupDate) {
                const pickupDate = parseISO(reservationDetails.pickupDate);
                const returnDate = new Date(pickupDate);
                returnDate.setDate(pickupDate.getDate() + 3);

                const formattedReturnDate = format(returnDate, 'yyyy-MM-dd');

                const shouldUpdateReturnDate = !reservationDetails.returnDate;
                const shouldUpdateReturnTime = !reservationDetails.returnTime;

                setReservationDetails((prevDetails) => ({
                    ...prevDetails,
                    ...(shouldUpdateReturnDate && { returnDate: formattedReturnDate }),
                    ...(shouldUpdateReturnTime && { returnTime: value }),
                }));
            }
        }

    };

    function generateTimes() {
        const times = [];
        const start = 0;
        const end = 23 * 60 + 45;
        for (let minutes = start; minutes <= end; minutes += 15) {
            const hrs = Math.floor(minutes / 60);
            const mins = minutes % 60;
            const time = `${('0' + hrs).slice(-2)}:${('0' + mins).slice(-2)}`;
            times.push(time);
        }
        return times;
    }

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <DateInputWithCalendar
                label="Pickup Date"
                name="pickupDate"
                value={reservationDetails.pickupDate || ''}
                onChange={handleChange}
                error={error.pickupDate}
                setError={setError}
                className="max-w-xs"
                required
            />
            <UnifiedDropdown
                label="Pickup Time"
                name="pickupTime"
                value={reservationDetails.pickupTime}
                items={times}
                onChange={handleChange}
                error={error.pickupTime}
                setError={setError}
                renderItem={(time) => <span>{time}</span>}
                displayValue={(time) => <span>{time}</span>}
                className="max-w-xs"
                required
                isOpen={isPickupTimeOpen}
                setIsOpen={setIsPickupTimeOpen}
            />
            <DateInputWithCalendar
                label="Return Date"
                name="returnDate"
                value={reservationDetails.returnDate || ''}
                onChange={handleChange}
                error={error.returnDate}
                setError={setError}
                className="max-w-xs"
                required
            />
            <UnifiedDropdown
                label="Return Time"
                name="returnTime"
                value={reservationDetails.returnTime}
                items={times}
                onChange={handleChange}
                error={error.returnTime}
                setError={setError}
                renderItem={(time) => <span>{time}</span>}
                displayValue={(time) => <span>{time}</span>}
                className="max-w-xs"
                required
            />
            <UnifiedDropdown
                label="Pickup Location"
                name="pickupLocation"
                value={reservationDetails.pickupLocation || null}
                items={locations}
                onChange={handleChange}
                error={error.pickupLocation}
                setError={setError}
                renderItem={(item) => <span>{item.Address}</span>}
                displayValue={(item) => <span>{item.Address}</span>}
                className="max-w-xs"
                required
                compareFunction={(value, item) => value && item && value.StationID === item.StationID}
            />
            <UnifiedDropdown
                label="Drop-off Location"
                name="dropoffLocation"
                value={reservationDetails.dropoffLocation || null}
                items={locations}
                onChange={handleChange}
                error={error.dropoffLocation}
                setError={setError}
                renderItem={(item) => <span>{item.Address}</span>}
                displayValue={(item) => <span>{item.Address}</span>}
                className="max-w-xs"
                required
                compareFunction={(value, item) => value && item && value.StationID === item.StationID}
            />
            <UnifiedDropdown
                label="Select Car"
                name="selectedCar"
                value={reservationDetails.selectedCar || null}
                items={cars}
                onChange={handleChange}
                error={error.selectedCar}
                setError={setError}
                renderItem={(item) => (
                    <>
                        <img
                            src={item.imageURL}
                            alt={`${item.Make} ${item.Model} ${item.Year}`}
                            className="w-8 h-8 rounded-lg mr-2"
                        />
                        <span>{`${item.Make} ${item.Model} ${item.Year} ${item.Transmission} ${item.FuelType}`}</span>
                    </>
                )}
                displayValue={(item) => (
                    <>
                        <img
                            src={item.imageURL}
                            alt={`${item.Make} ${item.Model} ${item.Year}`}
                            className="w-8 h-8 rounded-lg mr-2"
                        />
                        <span>{`${item.Make} ${item.Model} ${item.Year} ${item.Transmission[0]} ${item.FuelType[0]}`}</span>
                    </>
                )}
                className="max-w-xs"
                required
                compareFunction={(value, item) => value && item && value.CarID === item.CarID}
            />
            <div className="mt-20">
                <Button
                    icon={<MdKeyboardArrowRight size={24} />}
                    label=""
                    onClick={handleNextStep}
                />
            </div>
        </div>
    );
};

export default StepOne;
