import React from "react";

const Button = ({ label, icon, onClick, className = '', size = 'normal' }) => {
    const baseStyles =
        "flex items-center justify-center rounded-xl shadow-md transition-all duration-300";

    // Styles for normal and small buttons
    const sizeStyles =
        size === 'small'
            ? "px-3 py-1 text-sm" // Smaller padding and font size
            : "px-6 py-2 text-base";

    const bgStyles = "bg-brand-500 dark:bg-brand-400 text-white hover:bg-brand-600 hover:dark:bg-brand-600";

    return (
        <div className="flex justify-center">
            <button
                onClick={onClick}
                type="button"
                className={`${baseStyles} ${sizeStyles} ${bgStyles} ${className}`}
            >
                {icon && <span className="mr-2">{icon}</span>}
                {label}
            </button>
        </div>
    );
};

export default Button;
