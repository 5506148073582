import React, { useState } from 'react';

function InputField(props) {
    const { label, id, extra, type, placeholder, variant, value, onChange, disabled, name, tooltip } = props;

    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div className={`${extra}`} style={{ position: 'relative' }}>
            <label
                htmlFor={id}
                className={`text-sm text-navy-700 dark:text-white ${variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"}`}
                style={{ display: 'flex', alignItems: 'center' }}
            >
                {label}
                {tooltip && (
                    <span
                        style={{
                            marginLeft: '8px',
                            fontSize: '12px',
                            color: '#888',
                            cursor: 'pointer',
                            border: '1px solid #ccc',
                            borderRadius: '50%',
                            width: '16px',
                            height: '16px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                    >
                        ?
                    </span>
                )}
            </label>
            {tooltip && showTooltip && (
                <div
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: '0',
                        marginTop: '8px',
                        padding: '8px',
                        backgroundColor: '#fff',
                        color: '#000',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        zIndex: 10,
                        width: '200px',
                        fontSize: '12px',
                    }}
                >
                    {tooltip}
                </div>
            )}
            <input
                disabled={disabled}
                name={name}
                type={type}
                id={id}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${disabled
                        ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
                        : `border-gray-200 dark:!border-red dark:text-white ${extra}`
                    }`}
            />
        </div>
    );
}

export default InputField;
