// ActionsCell.js
import React from 'react';
import { useNavigate } from "react-router-dom";
import Dropdown from 'components/dropdown/DotsDropdown';
import { FaEye, FaDownload, FaTimes, FaEdit } from 'react-icons/fa';
import { fetchExtras } from './fetchExtras';
import { createDetailedContent } from './../../../../../components/Modal/RsvModalgen';
import { generateReservationPDF } from './pdfGenerator';
import { checkCancel } from './checkCancel';

const ActionsCell = ({ row, toggleDropdown, setIsModalOpen, setModalContent, stationsData, carsData }) => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-end relative">
      <Dropdown
        button={
          <button
            onClick={() => toggleDropdown(row.original)}
            className="group flex flex-col items-center justify-center p-0 w-8"
          >
            <div className="w-1 h-1 bg-gray-500 group-hover:bg-brand-500 group-hover:dark:bg-brand-400 rounded-full mb-1"></div>
            <div className="w-1 h-1 bg-gray-500 group-hover:bg-brand-500 group-hover:dark:bg-brand-400 rounded-full mb-1"></div>
            <div className="w-1 h-1 bg-gray-500 group-hover:bg-brand-500 group-hover:dark:bg-brand-400 rounded-full"></div>
          </button>
        }
        classNames="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-xl hover:rounded-xl dark:rounded-xl shadow-lg"
        animation="origin-top-right transition-all duration-300 ease-in-out"
      >
        <ul>
          {/* View Details Option */}
          <li
            className="p-2 hover:bg-gray-200 hover:dark:bg-navy-600 cursor-pointer dark:text-white flex items-center dark:bg-navy-700 rounded-t-xl"
            onClick={async () => {
              const extras = await fetchExtras(row.original.ReservationID);
              setModalContent({
                title: "Details",
                content: createDetailedContent(row.original, stationsData, carsData, extras),
                row: row.original,
              });
              setIsModalOpen(true);
            }}
          >
            <FaEye className="mr-2" />
            View Details
          </li>
          {/* Download PDF Option */}
          <li
            className="p-2 hover:bg-gray-200 hover:dark:bg-navy-600 cursor-pointer dark:text-white flex items-center dark:bg-navy-700"
            onClick={async () => {
              const extras = await fetchExtras(row.original.ReservationID);
              generateReservationPDF(row.original, stationsData, carsData, extras);
            }}
          >
            <FaDownload className="mr-2" />
            Download PDF
          </li>
          {/* Edit Reservation Option */}
          <li
            className="p-2 hover:bg-gray-200 hover:dark:bg-navy-600 cursor-pointer dark:text-white flex items-center dark:bg-navy-700"
            onClick={() => navigate(`/admin/reservations/${row.original.ReservationID}`)}
          >
            <FaEdit className="mr-2" />
            Edit Reservation
          </li>
          {/* Cancel Option */}
          <li
            className="p-2 hover:bg-gray-200 hover:dark:bg-navy-600 cursor-pointer text-red-500 flex items-center dark:bg-navy-700 rounded-b-xl"
            onClick={() => checkCancel(row.original, setIsModalOpen, setModalContent)}
          >
            <FaTimes className="mr-2" />
            Cancel
          </li>
        </ul>
      </Dropdown>
    </div>
  );
};

export default ActionsCell;
