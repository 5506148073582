import React from 'react';

// Helper function to get placement classes based on `placement` prop
const getTooltipPlacementClasses = (placement) => {
    switch (placement) {
        case 'top':
            return 'bottom-full left-1/2 transform -translate-x-1/2 mb-2';
        case 'bottom':
            return 'top-full left-1/2 transform -translate-x-1/2 mt-2';
        case 'left':
            return 'top-1/2 right-full transform -translate-y-1/2 mr-2';
        case 'right':
            return 'top-1/2 left-full transform -translate-y-1/2 ml-2';
        default:
            return 'bottom-full left-1/2 transform -translate-x-1/2 mb-2';
    }
};

const TooltipWrapper = ({ content, icon, imageURL, placement = 'top' }) => {
    return (
        <div className="relative inline-block group">
            {/* Tooltip Trigger (Icon) */}
            <span className="cursor-pointer text-xl text-gray-700 hover:text-blue-500">
                <i className={icon}></i> {/* This is the trigger icon */}
            </span>

            {/* Tooltip Content (Image or Text) */}
            <div
                className={`absolute z-10 hidden group-hover:block ${getTooltipPlacementClasses(
                    placement
                )} px-2 py-1 text-xs font-medium text-white bg-gray-800 rounded-lg shadow-lg opacity-100 transition-all duration-200`}
            >
                {/* Conditionally render image or text */}
                {imageURL ? (
                    <img
                        src={imageURL}
                        alt="Tooltip"
                        className="w-24 h-24 max-w-xs max-h-32 object-cover rounded-md"
                        onError={(e) => e.target.src = 'path/to/placeholder-image.jpg'}  // Handle image load failure
                    />
                ) : (
                    <span>{content}</span>
                )}
            </div>
        </div>
    );
};


export default TooltipWrapper;
