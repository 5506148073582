import React, { useEffect, useState } from "react";
import Card from 'components/card';
import apiClient from './../axiosInstance';
import InputField from '../../../components/fields/InputField';

const Profile = () => {
    const [company, setCompany] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiClient.get('/company');
                const data = response.data.company;
                setCompany(data);
            } catch (err) {
                console.error('Error fetching company data:', err);
                setError(err.message);
            }
        };

        fetchData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCompany((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    return (
        <div className="flex w-full flex-col ">

            <div className="mt-5">
                {error ? (
                    <p className="text-red-500">Error: {error}</p>
                ) : (
                    company && (
                        <Card extra="p-5 mb-1 flex h-auto">
                                <div className="flex flex-col items-center md:items-start w-full gap-5 md:grid md:grid-cols-2 lg:grid-cols-3">
                                <InputField
                                    label="Grace Period (minutes)"
                                    name="GracePeriodMinutes"
                                    type="number"
                                    value={company.GracePeriodMinutes || '0'}
                                    onChange={handleInputChange}
                                    placeholder='zero by default'
                                    tooltip="This field specifies the grace period in minutes before an additional charge for the next day is applied."
                                />

                                <InputField
                                    string label="Location Out of Hours fee"
                                    name="CostOOH"
                                    type="number"
                                    value={company.CostOOH || ''}
                                    onChange={handleInputChange}
                                    tooltip="This field specifies the location fee in Euros applied for out-of-hours service across all locations."
                                />

                                <InputField
                                    label="Booking Ahead (minutes)"
                                    name="BookAfterMinutes"
                                    type="number"
                                    value={company.BookAfterMinutes || ''}
                                    onChange={handleInputChange}
                                    tooltip="This field specifies the number of minutes in advance that a booking can be made visible."
                                />
                                <InputField
                                    label="Time Between Reservations"
                                    name="TimeBetweenReservations"
                                    type="number"
                                    value={company.TimeBetweenReservations || '0'}
                                    onChange={handleInputChange}
                                    tooltip="This field specifies the number of minutes a car will be made available to other customers after the previous drop-off"
                                />
                            </div>
                        </Card>
                    )
                )}
            </div>
        </div>
    );
};

export default Profile;
