import React, { useEffect, useState } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import Button from "components/button/Button";

import {
    format,
    subMonths,
    startOfMonth,
    endOfMonth,
    eachDayOfInterval,
    parseISO,
} from "date-fns";
import apiClient from "./../axiosInstance";

const CarsCalendar = () => {
    const [cars, setCars] = useState([]);
    const [reservations, setReservations] = useState([]);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiClient.get("/reservations-cars");

                setCars(response.data.cars || []);
                setReservations(response.data.reservations || []);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    const daysInMonth = eachDayOfInterval({
        start: startOfMonth(currentMonth),
        end: endOfMonth(currentMonth),
    });

    const getRentalCounts = () => {
        const rentalCounts = {};

        reservations.forEach((reservation) => {
            const carId = reservation.CarID;
            const start = parseISO(reservation.PickupDatetime);
            const end = parseISO(reservation.ReturnDatetime);

            // Ensure dates are in UTC
            let currentDate = new Date(start.getTime());
            currentDate.setUTCHours(0, 0, 0, 0);

            const endDate = new Date(end.getTime());
            endDate.setUTCHours(0, 0, 0, 0);

            while (currentDate <= endDate) {
                const dateKey = format(currentDate, "yyyy-MM-dd");

                if (!rentalCounts[carId]) rentalCounts[carId] = {};
                if (!rentalCounts[carId][dateKey]) rentalCounts[carId][dateKey] = 0;

                rentalCounts[carId][dateKey] += 1; // Increment the count for that day

                currentDate.setUTCDate(currentDate.getUTCDate() + 1);
            }
        });

        return rentalCounts;
    };

    const rentalCounts = getRentalCounts();

    // Today's date in UTC
    const today = format(new Date(), "yyyy-MM-dd");

    return (
        <div className="w-full p-4">
            {/* Month Navigation */}
            <div className="flex justify-between items-center mb-4">
                <Button
                    onClick={() => setCurrentMonth(subMonths(currentMonth, 1))}
                    icon={<MdChevronLeft size={24} />}
                    label="" />
                <h2 className="text-xl font-semibold text-black dark:text-white">
                    {format(currentMonth, "MMMM yyyy")}
                </h2>
                <Button
                    onClick={() => setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1))}
                    icon={<MdChevronRight size={24} />}
                    label="" />
            </div>

            {/* Custom Calendar Table */}
            <div className="overflow-x-auto">
                <table className="min-w-full border-collapse border border-gray-200 dark:border-navy-700">
                    {/* Header Row - Days of the Month */}
                    <thead>
                        <tr>
                            <th className="border p-2 bg-gray-200 dark:bg-navy-800 dark:text-white font-bold text-center">
                                Car
                            </th>
                            {daysInMonth.map((day) => (
                                <th
                                    key={day}
                                    className={`border p-2 font-bold text-center ${format(day, "yyyy-MM-dd") === today
                                            ? "bg-green-500 dark:bg-green-500 text-black"
                                            : "bg-gray-200 dark:bg-navy-800 dark:text-white"
                                        }`}
                                >
                                    {format(day, "d")}
                                </th>
                            ))}
                        </tr>
                    </thead>

                    {/* Rows for Each Car */}
                    <tbody>
                        {cars.map((car) => (
                            <tr key={car.CarID}>
                                {/* Car Name Column */}
                                <td className="border p-2 font-semibold text-center bg-gray-100 dark:bg-navy-700 dark:text-white">
                                    {car.Make} {car.Model} {car.FuelType[0]}
                                </td>

                                {/* Rental Counts for Each Day */}
                                {daysInMonth.map((day) => {
                                    const dateKey = format(day, "yyyy-MM-dd");
                                    const rentalCount = rentalCounts[car.CarID]?.[dateKey] || 0;

                                    return (
                                        <td
                                            key={`${car.CarID}-${dateKey}`}
                                            className={`border p-2 text-center ${rentalCount > 0
                                                    ? "bg-red-200 dark:bg-red-800 dark:text-white font-bold"
                                                    : "bg-white dark:bg-navy-800 dark:text-white"
                                                }`}
                                        >
                                            {rentalCount > 0 ? rentalCount : ""}
                                        </td>

                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CarsCalendar;
