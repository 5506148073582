import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Card from 'components/card';
import apiClient from './../axiosInstance';

const Locations = () => {
    const [stations, setStations] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiClient.get('/location-names');
                console.log(response);
                const { stations } = response.data;
                setStations(stations);
            } catch (err) {
                console.error('Error fetching station data:', err);
                setError('Failed to load station data. Please try again later.');
            }
        };

        fetchData();
    }, []);

    const handleLocationClick = (stationID) => {
        navigate(`/admin/locations/${stationID}`);
    };

    return (
        <div className="flex w-full flex-col">
            <div className="mt-5">
                {error ? (
                    <p className="text-red-500">{error}</p>
                ) : (
                    <Card extra="p-5 mb-1 flex flex-col h-auto">
                        {stations.length > 0 ? (
                            <table className="w-full text-left border-collapse">
                                <thead>
                                    <tr className="dark:bg-navy-800">
                                        <th className="py-2 px-4 border-b dark:text-white">Address</th>
                                        <th className="py-2 px-4 border-b dark:text-white">City</th>
                                        <th className="py-2 px-4 border-b dark:text-white">Postal Code</th>
                                        <th className="py-2 px-4 border-b dark:text-white">Country</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {stations.map((station, rowIndex) => (
                                        <tr
                                            key={station.StationID}
                                            className={rowIndex % 2 === 0
                                                ? 'bg-white dark:bg-navy-800 hover:bg-gray-300 dark:hover:bg-navy-600 transition-all cursor-pointer'
                                                : 'bg-gray-100 dark:bg-navy-700 hover:bg-gray-300 dark:hover:bg-navy-600 transition-all cursor-pointer'}
                                            onClick={() => handleLocationClick(station.StationID)}
                                        >
                                            <td className="py-2 px-4 dark:text-white">{station.Address}</td>
                                            <td className="py-2 px-4 dark:text-white">{station.City}</td>
                                            <td className="py-2 px-4 dark:text-white">{station.PostalCode}</td>
                                            <td className="py-2 px-4 dark:text-white">{station.Country}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p className="text-center">Loading stations...</p>
                        )}
                    </Card>
                )}
            </div>
        </div>
    );
};

export default Locations;
