import React from 'react';

const InputField = ({ label, type, name, value, onChange, error, setError, className, placeholder }) => {
    const handleChange = (e) => {
        onChange(e);
        if (error) {
            setError((prevError) => ({
                ...prevError,
                [name]: false,
            }));
        }
    };

    return (
        <div className="flex flex-col">
            <label className="mb-2 text-sm font-semibold">{label}</label>
            <input
                type={type}
                name={name}
                value={value}
                onChange={handleChange}
                className={`${className} ${error ? 'border-red-500' : 'border-gray-300'} rounded-xl p-2 dark:bg-navy-800 border`}
                placeholder={placeholder}
            />
        </div>
    );
};

export default InputField;
