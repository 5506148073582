import React, { useEffect, useState } from "react";
import Card from 'components/card';
import apiClient from './../axiosInstance';
import InputField from '../../../components/fields/InputField';
import Button from '../../../components/button/Button';

const Settings = () => {
    const [company, setCompany] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiClient.get('/settings');
                const data = response.data.company;
                setCompany(data);
            } catch (err) {
                console.error('Error fetching company data:', err);
                setError('Failed to fetch company data. Please try again later.');
            }
        };

        fetchData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCompany((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        try {
            await apiClient.put('/settings', company);
            alert('Company details updated successfully!');
        } catch (err) {
            console.error('Error updating company details:', err);
            setError('Failed to update company details. Please try again.');
        }
    };

    return (
        <div className="flex w-full flex-col">
            <div className="mt-5">
                {error ? (
                    <p className="text-red-500">{error}</p>
                ) : (
                    company && (
                        <Card extra="p-5 mb-1 flex flex-col h-auto">
                            <div className="flex flex-col items-center md:items-start w-full gap-5 md:grid md:grid-cols-2">
                                <InputField
                                    label="Company Name"
                                    name="CompanyName"
                                    value={company.CompanyName || ''}
                                    onChange={handleInputChange}
                                    tooltip="Company Name will be visible from customers."
                                />
                                <InputField
                                    label="Email"
                                    name="Email"
                                    value={company.Email || ''}
                                    onChange={handleInputChange}
                                    tooltip="Email that reservation copies will be sent."
                                />
                                <InputField
                                    label="Main Address"
                                    name="Address"
                                    value={company.Address || ''}
                                    onChange={handleInputChange}
                                />

                                <InputField
                                    label="Phone Number"
                                    name="PhoneNumber"
                                    value={company.PhoneNumber || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="flex justify-center mt-8">
                                <Button
                                    onClick={handleSave}
                                    label="Save"
                                />
                            </div>
                        </Card>
                    )
                )}
            </div>
        </div>
    );
};

export default Settings;
