import React, { useEffect, useState } from "react";
import Card from 'components/card';
import apiClient from './../axiosInstance';

const Cars = () => {
    const [cars, setCars] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiClient.get('/cars');
                setCars(response.data || []);
            } catch (err) {
                console.error("Error fetching car data:", err);
                setError("Failed to load car data. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) return <div className="flex justify-center items-center h-screen">Loading car data...</div>;
    if (error) return <div className="text-red-500 flex justify-center items-center h-screen">{error}</div>;

    return (
        <div className="flex w-full flex-col gap-5">
            <div className="mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
                {cars.length === 0 ? (
                    <Card extra="p-5 mb-1 flex h-auto">
                        <div className="flex justify-center items-center w-full h-full">
                            <p className="text-lg font-semibold">Loading cars...</p>
                        </div>
                    </Card>
                ) : (
                    cars.map((car) => (
                        <Card key={car.CarID} extra="p-5 mb-1 flex h-auto">
                            <div className="flex flex-col md:flex-row w-full">
                                {/* Car Image */}
                                <div className="w-full md:w-1/2 h-auto flex-shrink-0 overflow-hidden">
                                    <img
                                        src={car.imageURL}
                                        alt={`${car.Make} ${car.Model}`}
                                        className="w-full h-auto"
                                        style={{ objectFit: "contain" }}
                                    />
                                    {/* Title Section */}
                                    <div className="text-center mb-5">
                                        <span className="block font-bold text-lg">
                                            {car.Make} {car.Model} {car.Year}
                                        </span>
                                        <span className="block">
                                            {car.Transmission} | {car.FuelType}
                                        </span>
                                    </div>
                                </div>

                                <div className="flex flex-col w-full md:w-1/2 p-5">
                                    
                                    {/* Details Section in Three Columns */}
                                    <div className="grid grid-cols-3 gap-5">
                                        <div className="flex flex-col items-center">
                                            <span className="font-semibold">Doors</span>
                                            <span>{car.Doors}</span>
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <span className="font-semibold">Seats</span>
                                            <span>{car.Seats}</span>
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <span className="font-semibold">Min Age</span>
                                            <span>{car.MinAge}</span>
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <span className="font-semibold">Max Age</span>
                                            <span>{car.MaxAge}</span>
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <span className="font-semibold">Excess</span>
                                            <span>
                                                {car.ExcessDamage}
                                                {"\u20AC"}
                                            </span>
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <span className="font-semibold">Deposit</span>
                                            <span>
                                                {car.Deposit}
                                                {"\u20AC"}
                                            </span>
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <span className="font-semibold">Size</span>
                                            <span>{car.Size}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    ))
                )}
            </div>
        </div>


    );
};

export default Cars;
