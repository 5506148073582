import React from 'react';
import { createColumnHelper } from "@tanstack/react-table";
import { formatDate, getStationInfo, getCarInfo } from './Utils';
import StatusIndicator from './StatusIndicator';
import ActionsCell from './ActionsCell';
const columnHelper = createColumnHelper();

export const createColumns = (stationsData, carsData, toggleDropdown, selectedRow, setIsModalOpen, setModalContent) => [

    {
        accessor: "CreatedAt",
        header: "Created At",
        cell: (info) => formatDate(info.getValue()),
    },
    {
        accessor: (row) => `${row.Name} ${row.Surname}`,
        header: "Full Name",
        cell: (info) => info.getValue(),
    },
    {
        accessor: (row) => getStationInfo(row.PickupLocation, stationsData),
        header: "Pick Up Location",
        enableSorting: false, 
        cell: (info) => {
            const stationInfo = info.getValue();
            return stationInfo.Address;
        },
    },
    {
        accessor: (row) => getStationInfo(row.ReturnLocation, stationsData),
        header: "Drop Off Location",
        enableSorting: false, 
        cell: (info) => {
            const stationInfo = info.getValue();
            return stationInfo.Address;
        },
    },
    {
        accessor: "PickupDatetime",
        header: "Pickup Date",
        cell: (info) => formatDate(info.getValue()),
    },
    {
        accessor: "ReturnDatetime",
        header: "Return Date",
        cell: (info) => formatDate(info.getValue()),
    },
    {
        accessor: "Status",
        header: "Status",
        cell: (info) => (
            <div className="text-sm font-bold text-navy-700 dark:text-white">
                <StatusIndicator status={info.getValue()} />
            </div>
        ),
    },
    {
        accessor: (row) => getCarInfo(row.CarID, carsData),
        header: "Car",
        enableSorting: false, 
        cell: (info) => {
            const carInfo = info.getValue();
            return `${carInfo.Make} ${carInfo.Model}`;
        },
    }, {
        accessor: "Channel",
        header: "Channel",
        cell: (info) => info.getValue(),
    },
    {
        accessor: "Price",
        header: "Price",
        cell: (info) => info.getValue(),
    },
    {
        id: 'details',
        header: 'Actions',
        enableSorting: false,
        cell: ({ row }) => (
            <ActionsCell
                row={row}
                toggleDropdown={toggleDropdown}
                setIsModalOpen={setIsModalOpen}
                setModalContent={setModalContent}
                stationsData={stationsData}
                carsData={carsData}
            />
        ),
    },

].map((col) => {
    // Determine if the accessor is a function or a string
    const accessorFn = typeof col.accessor === 'function' ? col.accessor : (row) => row[col.accessor];

    return columnHelper.accessor(accessorFn, {
        id: col.id || col.header.replace(/\s+/g, '').toLowerCase(),
        header:
            col.header === 'Download' ? null : (
                <p className="text-sm font-bold text-gray-600 dark:text-white">{col.header}</p>
            ),
        cell: (info) => (
            <div className="text-sm text-navy-700 dark:text-white ">{col.cell(info)}</div>
        ),
        enableSorting: col.enableSorting !== undefined ? col.enableSorting : true,
    });
});
