import React, { useState } from "react";

function TextField(props) {
    const { label, id, extra, placeholder, cols, rows, value, state, disabled, tooltip, onChange } = props;
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div className={`${extra}`} style={{ position: 'relative' }}>
            <label
                htmlFor={id}
                className="ml-3 mb-2 text-sm font-bold text-navy-700 dark:text-white"
                style={{ display: 'flex', alignItems: 'center' }}
            >
                {label}
                {/* Tooltip icon */}
                {tooltip && (
                    <span
                        style={{
                            marginLeft: '8px',
                            fontSize: '12px',
                            color: '#888',
                            cursor: 'pointer',
                            border: '1px solid #ccc',
                            borderRadius: '50%',
                            width: '16px',
                            height: '16px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                    >
                        ?
                    </span>
                )}
            </label>

            {/* Tooltip content */}
            {tooltip && showTooltip && (
                <div
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: '0',
                        marginTop: '8px',
                        padding: '8px',
                        backgroundColor: '#fff',
                        color: '#000',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        zIndex: 10,
                        width: '200px',
                        fontSize: '12px',
                    }}
                >
                    {tooltip}
                </div>
            )}

            {/* Textarea field */}
            <div>
                <textarea
                    cols={cols}
                    rows={rows}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    className={`flex w-full items-center justify-center rounded-xl border bg-white/0 pl-3 pt-3 text-sm outline-none ${disabled === true
                            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
                            : state === "error"
                                ? "!border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
                                : state === "success"
                                    ? "!border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                                    : state === "normal"
                                        ? "placeholder:text-green-500 dark:!border-white dark:text-white"
                                        : disabled === true
                                            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
                                            : "border-gray-200 dark:!border-white/10 dark:text-white"
                        }`}
                    name={id}
                    id={id}
                />
            </div>
        </div>
    );
}

export default TextField;
