import { formatDate, getStationInfo, getCarInfo } from './../../views/admin/reservations/components/Helpers/Utils';

export const createDetailedContent = (row, stationsData, carData, extras = []) => {
    const pickupStation = getStationInfo(row.PickupLocation, stationsData);
    const returnStation = getStationInfo(row.ReturnLocation, stationsData);
    const carInfo = getCarInfo(row.CarID, carData);

    return (
        <div className="p-4 max-w-screen-md mx-auto overflow-x-auto">
            <table className="min-w-full table-auto border-collapse border border-gray-300">
                <tbody>
                    <tr className="border-b">
                        <td className="px-4 py-2 font-semibold break-words">Pickup Date:</td>
                        <td className="px-4 py-2">{formatDate(row.PickupDatetime)}</td>
                    </tr>
                    <tr className="border-b">
                        <td className="px-4 py-2 font-semibold break-words">Return Date:</td>
                        <td className="px-4 py-2">{formatDate(row.ReturnDatetime)}</td>
                    </tr>
                    <tr className="border-b">
                        <td className="px-4 py-2 font-semibold break-words">Pickup Location:</td>
                        <td className="px-4 py-2">{pickupStation.Address}</td>
                    </tr>
                    <tr className="border-b">
                        <td className="px-4 py-2 font-semibold break-words">Return Location:</td>
                        <td className="px-4 py-2">{returnStation.Address}</td>
                    </tr>
                    <tr className="border-b">
                        <td className="px-4 py-2 font-semibold break-words">Car:</td>
                        <td className="px-4 py-2">{`${carInfo.Make} ${carInfo.Model} ${carInfo.Year}`}</td>
                    </tr>
                    <tr className="border-b">
                        <td className="px-4 py-2 font-semibold break-words">Car Details:</td>
                        <td className="px-4 py-2">{`${carInfo.Transmission} ${carInfo.FuelType}`}</td>
                    </tr>
                    <tr className="border-b">
                        <td className="px-4 py-2 font-semibold break-words">Full Name:</td>
                        <td className="px-4 py-2">{`${row.Name} ${row.Surname}`}</td>
                    </tr>
                    <tr className="border-b">
                        <td className="px-4 py-2 font-semibold break-words">Email:</td>
                        <td className="px-4 py-2">{row.Email}</td>
                    </tr>
                    <tr className="border-b">
                        <td className="px-4 py-2 font-semibold break-words">Phone:</td>
                        <td className="px-4 py-2">{row.Phone}</td>
                    </tr>
                    <tr className="border-b">
                        <td className="px-4 py-2 font-semibold break-words">Flight:</td>
                        <td className="px-4 py-2">{row.Flight}</td>
                    </tr>

                    {/* Render Extras */}
                    <tr className="border-b">
                        <td className="px-4 py-2 font-semibold break-words">Extras:</td>
                        <td className="px-4 py-2">
                            {extras != null ? (
                                <ul>
                                    {extras.map((extra, index) => (
                                        <li key={index}>{extra.ExtraID}</li>
                                    ))}
                                </ul>
                            ) : (
                                "No extras selected"
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
