import React, { useState, useEffect, useRef, useCallback } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes.js";

export default function Admin(props) {
    const { ...rest } = props;
    const location = useLocation();
    const [open, setOpen] = useState(window.innerWidth >= 1200);
    const [openedByUser, setOpenedByUser] = useState(false);
    const [currentRoute, setCurrentRoute] = useState("Dashboard");
    const sidebarRef = useRef(null);

    const getActiveRoute = useCallback((routes) => {
        let activeRoute = "Dashboard";
        for (let i = 0; i < routes.length; i++) {
            const routePath = `${routes[i].layout}/${routes[i].path}`;
            if (location.pathname.startsWith(routePath)) {
                setCurrentRoute(routes[i].name);
                break;
            }
        }
        return activeRoute;
    }, [location.pathname]);

    useEffect(() => {
        getActiveRoute(routes);
    }, [location.pathname, getActiveRoute]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1200) {
                if (!openedByUser) {
                    setOpen(false);
                }
            } else {
                setOpen(true);
            }
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [openedByUser]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                sidebarRef.current &&
                !sidebarRef.current.contains(event.target) &&
                window.innerWidth <= 1200
            ) {
                setOpen(false);
                if (openedByUser) {
                    setOpenedByUser(false);
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [sidebarRef, openedByUser]);

    const handleOpenSidenav = () => {
        setOpen(true);
        setOpenedByUser(true);
    };

    const handleCloseSidenav = () => {
        if (openedByUser && window.innerWidth <= 1200) {
            setOpen(false);
            setOpenedByUser(false);
        }
    };

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/admin") {
                return (
                    <Route path={`/${prop.path}`} element={prop.component} key={key} />
                );
            } else {
                return null;
            }
        });
    };

    const getActiveNavbar = (routes) => {
        for (let i = 0; i < routes.length; i++) {
            const routePath = `${routes[i].layout}/${routes[i].path}`;
            if (location.pathname.startsWith(routePath)) {
                return routes[i].secondary;
            }
        }
        return false;
    };

    return (
        <div className="flex h-full w-full">
            <Sidebar
                ref={sidebarRef}
                open={open}
                onClose={handleCloseSidenav}
                openedByUser={openedByUser}
            />
            <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
                <main className={`mx-2 h-full flex-none transition-all md:pr-2 xl:ml-[250px]`}>
                    <div className="h-full">
                        <Navbar
                            onOpenSidenav={handleOpenSidenav}
                            logoText={"Karpadu React"}
                            brandText={currentRoute}
                            secondary={getActiveNavbar(routes)}
                            {...rest}
                        />
                        <div className="pt-5 mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
                            <Routes>
                                {getRoutes(routes)}
                                <Route path="/" element={<Navigate to="/admin/dashboard" replace />} />
                            </Routes>
                        </div>
                        <div className="p-3">
                            <Footer />
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}
