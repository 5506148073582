import React from "react";
import Dropdown from "components/dropdown";
import { useEffect } from "react";
import { FiAlignJustify } from "react-icons/fi";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import { IoMdNotificationsOutline } from "react-icons/io";
import avatar from "assets/img/avatars/goldy.jpg";
import { useAuth } from "../../views/auth/AuthContext";
import { Link, useNavigate } from "react-router-dom";

const Navbar = (props) => {
    const { onOpenSidenav, brandText } = props;
    const [darkmode, setDarkmode] = React.useState(false);
    const { logout } = useAuth();
    const companyName = localStorage.getItem("user") || "Partner";
    const navigate = useNavigate();

    const handleNavigateToSettings = () => {
        navigate("/admin/settings");
    };
    useEffect(() => {
        const savedDarkmode = localStorage.getItem("darkmode");

        if (savedDarkmode === "true") {
            setDarkmode(true);
            document.body.classList.add("dark");
        } else {
            setDarkmode(false);
            document.body.classList.remove("dark");
        }
    }, []);

    const toggleDarkmode = () => {
        if (darkmode) {
            document.body.classList.remove("dark");
            setDarkmode(false);
            localStorage.setItem("darkmode", "false");
        } else {
            document.body.classList.add("dark");
            setDarkmode(true);
            localStorage.setItem("darkmode", "true");
        }
    };
    return (
        <nav className="top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl  p-2 ">
            <div className="ml-[6px]">
                <div className="h-6 w-[224px] pt-1">
                </div>
                <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
                    <Link
                        to="#"
                        className="font-bold capitalize hover:text-navy-700 dark:hover:text-white cursor-default"
                    >
                        {brandText}
                    </Link>
                </p>
            </div>

            <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-900 dark:!bg-navy-700 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2 ">
                <span
                    className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
                    onClick={onOpenSidenav}
                >
                    <FiAlignJustify className="h-5 w-5" />
                </span>
                {/* start Notification */}
                <Dropdown
                    button={
                        <p className="cursor-pointer">
                            <IoMdNotificationsOutline className="h-4 w-4 text-gray-600 dark:text-white" />
                        </p>
                    }
                    animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
                    children={
                        <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
                            <div className="flex items-center justify-between">
                                <p className="text-base font-bold text-navy-700 dark:text-white">
                                    Notification
                                </p>
                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    Mark all read
                                </p>
                            </div>

                            {/* Notification items can be added here */}
                        </div>
                    }
                    classNames={"py-2 top-4 -left-[230px] md:-left-[440px] w-max"}
                />
                <div
                    className="cursor-pointer text-gray-600"
                    onClick={toggleDarkmode}
                >
                    {darkmode ? (
                        <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
                    ) : (
                        <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
                    )}
                </div>
                {/* Profile & Dropdown */}
                <Dropdown
                    button={
                        <img
                            className="h-10 w-10 rounded-full"
                            src={avatar}
                            alt="Partner"
                        />
                    }
                    children={
                        <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                            <div className="p-4">
                                <div className="flex items-center gap-2">
                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                        Welcome, {companyName}
                                    </p>{" "}
                                </div>
                            </div>
                            <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

                            <div className="flex flex-col items-start p-4">
                                <button
                                    type="button"
                                    className="text-sm font-medium text-gray-800 dark:text-white hover:dark:text-white"
                                    onClick={handleNavigateToSettings}
                                >
                                    Profile Settings
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 text-sm font-medium text-red-500 hover:text-red-500 transition duration-150 ease-out hover:ease-in"
                                    onClick={logout}
                                >Log Out
                                </button>
                            </div>
                        </div>
                    }
                    classNames={"py-2 top-8 -left-[180px] w-max"}
                />
            </div>
        </nav>
    );
};

export default Navbar;
