import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import apiClient from "./../../axiosInstance";
import Button from "components/button/Button";
import { MdKeyboardArrowLeft } from "react-icons/md";
import Card from 'components/card';
import ReservationFormStepOne from "components/reservationSteps/StepOne";
import ReservationFormStepTwo from "components/reservationSteps/StepTwo";
import { format } from 'date-fns';

const EditReservation = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [reservationDetails, setReservationDetails] = useState(null);
    const [stations, setStations] = useState([]);
    const [cars, setCars] = useState([]);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(true);
    const [step, setStep] = useState(1);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await apiClient.get(`/reservations-stations-cars/${id}`);
                const { reservations, stations, cars } = response.data;
                if (reservations && stations && cars) {
                    const reservationData = reservations.find(
                        res => res.ReservationID === parseInt(id, 10)
                    );
                    if (!reservationData) {
                        setError("Reservation not found.");
                    } else {
                        // Extract date and time components
                        const pickupDateTime = new Date(reservationData.PickupDatetime);
                        const returnDateTime = new Date(reservationData.ReturnDatetime);

                        const mappedReservationDetails = {
                            pickupDate: format(pickupDateTime, 'yyyy-MM-dd'),
                            pickupTime: format(pickupDateTime, 'HH:mm'),
                            returnDate: format(returnDateTime, 'yyyy-MM-dd'),
                            returnTime: format(returnDateTime, 'HH:mm'),

                            // Dropdown selections (full objects)
                            pickupLocation: stations.find(
                                station => station.StationID === reservationData.PickupLocation
                            ),
                            dropoffLocation: stations.find(
                                station => station.StationID === reservationData.ReturnLocation
                            ),
                            selectedCar: cars.find(car => car.CarID === reservationData.CarID),

                            name: reservationData.Name,
                            surname: reservationData.Surname,
                            email: reservationData.Email,
                            phone: reservationData.Phone,
                            flight: reservationData.Flight,
                            price: reservationData.Price,
                            age: reservationData.Age,
                        };

                        setReservationDetails(mappedReservationDetails);
                        setStations(stations);
                        setCars(cars);
                    }
                } else {
                    setError("Failed to load reservation, station, and car data.");
                }
            } catch (err) {
                setError("An error occurred while fetching data. Please try again.", err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const handleNextStep = () => {
        setStep(2);
    };

    const handlePreviousStep = () => {
        setStep(1);
    };

    const handleChange = (e) => {
        let name, value;

        if (e.target && e.target.name) {
            name = e.target.name;
            value = e.target.value;
        } else if (e.name && e.value !== undefined) {
            name = e.name;
            value = e.value;
        } else {
            console.warn('handleChange received unexpected input', e);
            return;
        }

        setReservationDetails((prev) => ({ ...prev, [name]: value }));
    };

    const handleSave = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await apiClient.put(`/update-reservation/${id}`, reservationDetails);
            navigate("/admin/reservations");
        } catch (err) {
            setError("An error occurred while saving changes. Please try again.");
        } finally {
            setLoading(false);
        }
    };


    const handleCancel = () => {
        navigate("/admin/reservations");
    };

    if (loading) {
        return <div className="text-center mt-8">Loading...</div>;
    }

    if (error && typeof error === 'string') {
        return <div className="text-red-500 text-center mt-8">{error}</div>;
    }

    return (
        <div className="flex w-full md:w-3/4 lg:w-3/4 md:ml-20 lg:ml-30 flex-col p-4">
            <Card extra="p-20">
            <div className="container mx-auto mt-4">
                {/* Heading and Button */}
                <div className="flex items-center space-x-4 mb-4">
                        <Button
                            onClick={handleCancel}
                            label={step === 2 ? "Reservations" : ""}
                            icon={step === 1 ? <MdKeyboardArrowLeft size={24} /> : null} 
                        />
                    <h1 className="text-xl font-bold">Edit</h1>
                </div>

                <form onSubmit={handleSave}>
                    {step === 1 && reservationDetails && (
                        <ReservationFormStepOne
                            reservationDetails={reservationDetails}
                            setReservationDetails={setReservationDetails}
                            cars={cars}
                            locations={stations}
                            error={error}
                            setError={setError}
                            handleNextStep={handleNextStep}
                            handleChange={handleChange}
                            isEditing={true}
                        />
                    )}
                    {step === 2 && reservationDetails && (
                        <>
                            <ReservationFormStepTwo
                                reservationDetails={reservationDetails}
                                handleChange={handleChange}
                                error={error}
                                isEditing={true}
                            />
                            <div className="flex flex-row justify-between mb-4 mt-10 gap-4">
                                <Button
                                    onClick={handlePreviousStep}
                                    icon={<MdKeyboardArrowLeft size={24} />}
                                />
                                <Button
                                    label="Save"
                                    onClick={handleSave}
                                    className="px-4 py-2 rounded text-white bg-brand-500 hover:bg-brand-600"
                                />
                            </div>
                        </>
                    )}
                </form>
            </div>
            </Card>
        </div>
    );
};

export default EditReservation;
