import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import ProtectedRoute from "./views/auth/ProtectedRoute";
import ResetPassword from "./views/auth/ResetPassword";
import { useAuth } from "./views/auth/AuthContext";

const App = () => {
    const location = useLocation();
    const { user } = useAuth();

    useEffect(() => {
        const pathname = location.pathname;
        if (!pathname.startsWith("/auth") && pathname !== "/auth/reset-password") {
            localStorage.setItem("lastPath", pathname);
        }
    }, [location]);

    const lastPath = localStorage.getItem("lastPath") || "/admin/dashboard";

    return (
        <Routes>
            <Route path="auth/*" element={<AuthLayout />}>
                <Route path="reset-password" element={<ResetPassword />} />
            </Route>

            <Route path="admin/*" element={<ProtectedRoute element={<AdminLayout />} />} />

            <Route
                path="/"
                element={user ? <Navigate to={lastPath} replace /> : <Navigate to="/auth/sign-in" replace />}
            />
        </Routes>
    );
};

export default App;
