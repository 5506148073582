import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import logo from './../../../../../assets/logo.png';
import { formatDate, getStationInfo, getCarInfo } from './../Helpers/Utils';

export const generateReservationPDF = (row, stationsData, carsData, extras) => {
    const doc = new jsPDF();

    let sumextras = 0;
    let extrasDetails = "No extras available"; // Default message if no extras

    if (extras && extras.length > 0) {
        extrasDetails = extras
            .map((extra, index) => `${index + 1}. ${extra.ExtraName} - ${extra.ExtraPrice} \u20AC`)
            .join('\n');

        extras.forEach((extra) => {
            sumextras += extra.ExtraPrice;
        });
    }

    // TITLE
    doc.setFontSize(22);
    doc.setFont("helvetica", "bold");
    doc.text("Reservation Details", 20, 20);

    // Add logo
    const img = new Image();
    img.src = logo;
    img.onload = () => {
        const imgWidth = img.width;
        const imgHeight = img.height;
        const aspectRatio = imgWidth / imgHeight;
        const desiredWidth = 50;
        const desiredHeight = desiredWidth / aspectRatio;
        doc.addImage(img, 'PNG', 150, 7, desiredWidth, desiredHeight);

        // LINE
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(1);
        doc.line(20, 30, 190, 30);

        // Prepare formatted dates and location names
        const formattedPickupDate = formatDate(row.PickupDatetime);
        const formattedDropoffDate = formatDate(row.ReturnDatetime);
        const formattedCreatedDate = formatDate(row.CreatedAt);
        const pickupLocationName = getStationInfo(row.PickupLocation, stationsData);
        const returnLocationName = getStationInfo(row.ReturnLocation, stationsData);
        const Car = getCarInfo(row.CarID, carsData);

        // Table data
        const tableData = [
            ["Field", "Information"],
            ["Order Created", formattedCreatedDate],
            ["Customer Full Name", row.Name + " " + row.Surname],
            ["Customer Email", row.Email],
            ["Customer Phone", row.Phone],
            ["Customer Country Code", row.CountryCode],
            ["Age", row.Age],
            ["Car", `${Car.Make} ${Car.Model} ${Car.Year} ${Car.Transmission} ${Car.FuelType}`],
            ["Excess/Deposit", `${Car.ExcessDamage}/${Car.Deposit}`],
            ["Location Pickup -> Return", pickupLocationName.Address + " -> " + returnLocationName.Address],
            ["Date Pickup -> Return", formattedPickupDate + " -> " + formattedDropoffDate],
            ["Car Price", row.Price + " \u20AC"],
            ["OOH Pickup", row.PickupOOH + " \u20AC"],
            ["OOH Return", row.ReturnOOH + " \u20AC"],
            ["Location Fee Pickup", row.PickupLocationFee + " \u20AC"],
            ["Location Fee Return", row.ReturnLocationFee + " \u20AC"],
            ["Extras", extrasDetails], // Display all extras with prices
            ["Commission", row.Commission + " \u20AC"],
            ["Total Price", `${row.Price + row.Commission + row.PickupOOH + row.ReturnOOH + row.PickupLocationFee + row.ReturnLocationFee + sumextras} \u20AC`]
        ];

        // Render table
        autoTable(doc, {
            startY: 40,
            head: [tableData[0]], // Include the header row
            body: tableData.slice(1),
            styles: {
                font: "helvetica",
                fontSize: 10,
                cellPadding: 2,
                overflow: 'linebreak',
                columnWidth: 'auto',
            },
            theme: 'striped',
        });

        




        //STATUS
        const statusText = row.Status === "Confirmed" ? "CONFIRMED" : (row.Status === "Cancelled" ? "CANCELLED" : null);
        if (statusText) {
            doc.setFontSize(24);

            if (row.Status === "Confirmed") {
                doc.setTextColor(0, 128, 0);
            } else if (row.Status === "Cancelled") {
                doc.setTextColor(255, 0, 0);
            }
            doc.setFont("helvetica", "bolditalic");
            const pageWidth = doc.internal.pageSize.width;
            const textWidth = doc.getTextWidth(statusText);
            const xPos = (pageWidth - textWidth) / 2;
            const yPos = doc.autoTable.previous.finalY + 20;
            doc.text(statusText, xPos, yPos);
        }

        // FOOTER
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text("Thank you for choosing our service!", 20, doc.internal.pageSize.height - 20);
        doc.text("www.karpadu.com", 150, doc.internal.pageSize.height - 20);

        // IDENTIFICATIONS
        doc.setFontSize(8);
        doc.setTextColor(150, 150, 150);
        const reservationInfo = `Reservation ID: ${row.ReservationID} / GUID: ${row.ReservationGUID} / External: ${row.ExternalReference}`;
        const bottomY = doc.internal.pageSize.height - 10;
        doc.text(reservationInfo, 20, bottomY);

        // Save the generated PDF
        doc.save(`Reservation_${row.ReservationID}.pdf`);
    };
};
