import { useState } from 'react';
import axios from 'axios';
import InputField from "components/fields/InputField";
import { MdKeyboardArrowLeft } from "react-icons/md";
import Button from 'components/button/Button';
import {  useNavigate } from "react-router-dom";

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');
        setLoading(true);
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, { email });
            setMessage('Password reset email sent. Please check your inbox.');
            setEmail('');
        } catch (error) {
            setError(error.response?.data?.message || 'Something went wrong. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    const goToSignIn = () => {
        navigate("/auth/sign-in");
    };
    return (
        <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
            <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <div className="flex items-center space-x-4">
                    <Button
                        icon={<MdKeyboardArrowLeft size={24} />}
                        label=""
                        onClick={goToSignIn}
                    />
                    <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                        Forgot Pass
                    </h4>
                </div>

                <div className="mb-6 flex items-center gap-3">
                    <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
                </div>

                {/* Success Message */}
                {message && (
                    <div
                        className="mb-4 text-green-500 text-sm"
                        aria-live="polite"
                    >
                        {message}
                    </div>
                )}

                {/* Error Message */}
                {error && (
                    <div
                        className="mb-4 text-red-500 text-sm"
                        aria-live="polite"
                    >
                        {error}
                    </div>
                )}

                <form onSubmit={handleForgotPassword}>
                    <InputField
                        className="mt-20"
                        type="email"
                        value={email}
                        placeholder="Type your email:"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <Button
                        className="mt-20 w-full"
                        type="submit"
                        label={loading ? 'Sending...' : 'Send Reset Link'}
                        onClick={handleForgotPassword}
                        disabled={loading}
                    />
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
