import React, { useEffect, useState } from "react";
import Card from 'components/card';
import Button from "components/button/Button";
import ReservationFormStepOne from "components/reservationSteps/StepOne";
import ReservationFormStepTwo from "components/reservationSteps/StepTwo";
import Modal from 'components/Modal/Modal';
import apiClient from './../axiosInstance';
import { MdKeyboardArrowLeft } from "react-icons/md";

const MakeReservationOverview = () => {
    const [cars, setCars] = useState([]);
    const [locations, setLocations] = useState([]);
    const [reservationDetails, setReservationDetails] = useState({
        pickupDate: '',
        pickupTime: '',
        returnDate: '',
        returnTime: '',
        pickupLocation: null,
        dropoffLocation: null,
        name: "",
        surname: "",
        email: "",
        phone: "",
        flight: "",
        price: 0,
        inclusions: "",
        age: 40,
        selectedCar: null,
    });

    const [error, setError] = useState({
        pickupDate: false,
        pickupTime: false,
        returnDate: false,
        returnTime: false,
        pickupLocation: false,
        dropoffLocation: false,
        selectedCar: false,
    });

    const [step, setStep] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({ title: "", content: "" });

    useEffect(() => {
        const fetchCarsAndStations = async () => {
            try {
                const response = await apiClient.get('/cars-stations');
                setCars(response.data.cars);
                setLocations(response.data.stations);
            } catch (err) {
                console.error('Error fetching cars and locations:', err);
            }
        };

        fetchCarsAndStations();
    }, []);

    const handleNextStep = (e) => {
        e.preventDefault();
        const {
            pickupLocation,
            dropoffLocation,
            selectedCar,
            pickupDate,
            pickupTime,
            returnDate,
            returnTime,
        } = reservationDetails;

        // Validate that date and time are present
        const hasPickupDateTime = pickupDate && pickupTime;
        const hasReturnDateTime = returnDate && returnTime;

        // Combine date and time into ISO strings
        let pickupTimestamp = null;
        let returnTimestamp = null;

        if (hasPickupDateTime) {
            const pickupDatetimeStr = `${pickupDate}T${pickupTime}`;
            pickupTimestamp = new Date(pickupDatetimeStr).getTime();
        }

        if (hasReturnDateTime) {
            const returnDatetimeStr = `${returnDate}T${returnTime}`;
            returnTimestamp = new Date(returnDatetimeStr).getTime();
        }

        const newErrorState = {
            pickupDate: !pickupDate,
            pickupTime: !pickupTime,
            returnDate: !returnDate || (hasReturnDateTime && returnTimestamp <= pickupTimestamp),
            returnTime: !returnTime || (hasReturnDateTime && returnTimestamp <= pickupTimestamp),
            pickupLocation: !pickupLocation,
            dropoffLocation: !dropoffLocation,
            selectedCar: !selectedCar,
        };

        setError(newErrorState);

        if (
            pickupLocation &&
            dropoffLocation &&
            selectedCar &&
            hasPickupDateTime &&
            hasReturnDateTime &&
            returnTimestamp > pickupTimestamp
        ) {
            // Optionally, store combined datetime strings in reservationDetails
            setReservationDetails((prevDetails) => ({
                ...prevDetails,
                pickupDatetime: `${pickupDate}T${pickupTime}`,
                returnDatetime: `${returnDate}T${returnTime}`,
            }));
            setStep(2);
        } else {
            console.log("Please fill out all required fields and ensure that the return date is after the pickup date.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setReservationDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
        // ...handle errors if needed...
    };
    const handlePreviousStep = (e) => {
        e.preventDefault();
        setStep(1);
    };

    const handleFinalSubmit = async (e) => {
  e.preventDefault();
  const {
    pickupDate,
    pickupTime,
    returnDate,
    returnTime,
    pickupLocation,
    dropoffLocation,
    selectedCar,
    // ... other fields
  } = reservationDetails;

  if (!pickupDate || !pickupTime || !returnDate || !returnTime || !pickupLocation || !dropoffLocation || !selectedCar) {
    console.log("Please fill out all required fields.");
    return;
  }

  // Combine date and time into datetime strings
  const pickupDatetime = `${pickupDate}T${pickupTime}`;
  const returnDatetime = `${returnDate}T${returnTime}`;

  try {
    await apiClient.post('/makereservation', {
      ...reservationDetails,
      pickupDatetime,
      returnDatetime,
    });

    setModalContent({
      title: "Success",
      content: "Reservation inserted successfully!"
    });
    setIsModalOpen(true);

    // Reset form
    setReservationDetails({
      pickupDate: '',
      pickupTime: '',
      returnDate: '',
      returnTime: '',
        name: "",
        surname: "",
        email: "",
        phone: "",
        flight: "",
        pickupLocation: null,
        dropoffLocation: null,
        price: 0,
        inclusions: "",
        age: 40,
        selectedCar: null,
    });
    setStep(1);

  } catch (err) {
    const errorMessage = err.response?.data?.error || 'Error submitting reservation';
    console.error(errorMessage);
  }
};


    return (
        <div className="flex w-full md:w-3/4 lg:w-3/4 md:ml-20 lg:ml-30 flex-col p-4">
            <Card extra="p-20">
                <form onSubmit={step === 1 ? handleNextStep : handleFinalSubmit}>
                    {step === 1 && (
                        <ReservationFormStepOne
                            reservationDetails={reservationDetails}
                            setReservationDetails={setReservationDetails}
                            cars={cars}
                            locations={locations}
                            error={error}
                            setError={setError}
                            handleNextStep={handleNextStep}
                            handleChange={handleChange}
                        />
                    )}
                    {step === 2 && (
                        <>
                            <ReservationFormStepTwo
                                reservationDetails={reservationDetails}
                                handleChange={(e) => {
                                    const { name, value } = e.target;
                                    setReservationDetails((prevDetails) => ({
                                        ...prevDetails,
                                        [name]: value,
                                    }));
                                }}
                                error={error}
                            />
                            <div className="flex flex-row justify-between mb-4 mt-10 gap-4">
                                <Button
                                    onClick={handlePreviousStep}
                                    icon={<MdKeyboardArrowLeft size={24} />}
                                    label=""/>
                                <Button label="Submit" onClick={handleFinalSubmit} />
                            </div>

                        </>
                    )}
                </form>
            </Card>

            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title={modalContent.title}
                content={modalContent.content}
                footer={modalContent.footer}
            />
        </div>
    );
};

export default MakeReservationOverview;
