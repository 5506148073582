import React, { useState, useEffect, useRef } from 'react';

const UnifiedDropdown = ({
    label,
    name,
    value,
    items = [],
    onChange,
    error,
    setError,
    renderItem,
    displayValue,
    className,
    required = false,
    isOpen: propIsOpen,
    setIsOpen: propSetIsOpen,
    compareFunction,
}) => {
    // Internal state if external control is not provided
    const [internalIsOpen, setInternalIsOpen] = useState(false);

    // Determine which state to use
    const isOpen = propIsOpen !== undefined ? propIsOpen : internalIsOpen;
    const setIsOpen = propSetIsOpen || setInternalIsOpen;

    const dropdownRef = useRef(null);
    const selectedRef = useRef(null); // Reference to the currently selected item

    // Scroll to the selected item when the dropdown opens
    useEffect(() => {
        if (isOpen && selectedRef.current) {
            selectedRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [isOpen]);

    // Close dropdown if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setIsOpen]);

    const handleSelect = (item) => {
        onChange({
            target: {
                name,
                value: item,
            },
        });
        setIsOpen(false);

        if (error) {
            setError((prevError) => ({
                ...prevError,
                [name]: false,
            }));
        }
    };

    // Default compare function
    const defaultCompareFunction = (value, item) => value === item;
    const compareFunc = compareFunction || defaultCompareFunction;

    return (
        <div className={`relative mt-4 ${className}`} ref={dropdownRef}>
            <label className="text-sm font-semibold">
                {label}
                {required && <span className="text-red-500"> *</span>}
            </label>
            <div
                className={`rounded-xl border ${error ? 'border-red-500' : 'border-gray-300'
                    } max-w-xs w-full`}
            >
                <button
                    type="button"
                    onClick={() => setIsOpen(!isOpen)}
                    className="w-full p-2 rounded-xl bg-white dark:bg-navy-800 flex justify-between items-center !text-black hover:bg-gray-200 dark:hover:bg-navy-700"
                >
                    <span className="flex items-center">
                        {value ? displayValue(value) : `${label}`}
                    </span>
                    <svg
                        className={`w-4 h-4 transform transition-transform ${isOpen ? 'rotate-180' : ''
                            }`}
                        viewBox="0 0 20 20"
                    >
                        <path d="M10 12l-6-6h12l-6 6z" />
                    </svg>
                </button>

                {isOpen && (
                    <ul className="absolute z-10 mt-1 w-full bg-white dark:bg-navy-800 rounded-xl shadow-lg border border-gray-300 max-h-60 overflow-auto">
                        {items.length > 0 ? (
                            items.map((item, index) => (
                                <li
                                    key={index}
                                    ref={compareFunc(value, item) ? selectedRef : null} // Assign ref to the selected item
                                    onClick={() => handleSelect(item)}
                                    className={`cursor-pointer p-2 hover:bg-gray-200 dark:hover:bg-navy-700 rounded-xl flex items-center ${compareFunc(value, item) ? 'bg-gray-100 dark:bg-navy-700' : ''}`}
                                >
                                    {renderItem(item)}
                                </li>
                            ))
                        ) : (
                            <li className="p-2">No options available</li>
                        )}
                    </ul>
                )}
            </div>
            {error && <span className="text-red-500 text-sm">{error}</span>}
        </div>
    );
};

export default UnifiedDropdown;
